<footer>
    <div class="copyright">
        <div class="container">
            <div class="footer-wrappers">
                <div>
                    © 2022 NVT Design Studio
                </div>
                <div>
                    <ul class="social-container">

                        <li>
                            <a href="https://www.facebook.com/nvtdesignstudio"><img src="assets/icon/facebook.svg" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/designstudio_nvt"><img src="assets/icon/instagram.svg" /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>